import React from 'react';
import '../../../config.css'

class SessionsWindow extends React.Component {


    state =  {
        loaded: false
    }

    styles = {
        color: this.props.color
    }

    componentDidMount() {
        // call api or anything
        setInterval(() => {
            this.setState({loaded: true});
        }, 10);
        
    }

    render() {
        let animate = this.state.loaded ? " fade-up-complete" : " fade-up-start";

        return (
            <div className='contentWindow'>
                <h1 className={'windowHeader' + animate} style={this.styles}>My Sessions</h1>
                <h2 className={'windowSubheader' + animate}>Everything you've worked on, in one convenient spot.</h2>
                <p className='plaintext'>Lorem ipsum this is just some text that is a placeholder for a paragraph element, and is meant to be read.</p>
            </div>
        )
    }
}

export default SessionsWindow;