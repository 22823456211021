


let Config = {
    lightmode: {
        background: {
            primary: "FFFFFF",
            secondary: "838383"
        },
        colors_primary: {
            red: "#f3474f",
            green: "#52db69",
            pink: "#ef3b7d",
            blue: "#4ab7e2",
            orange: "#f2a74e"
        },
        colors_dark: {
            red: "#f3474f",
            green: "#52db69",
            pink: "#ef3b7d",
            blue: "#4ab7e2",
            orange: "#f2a74e"
        },
        colors_light: {
            red: "#f3474f",
            green: "#52db69",
            pink: "#ef3b7d",
            blue: "#4ab7e2",
            orange: "#f2a74e"
        }
    },
    darkmode: {

    }
}

export default Config;