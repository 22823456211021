import React from 'react';
import './app.css';
import TabbedView from './tabbedView/tabbedView';
import MainWindow from './mainWindow/mainWindow';
import Config from '../config.js'




class App extends React.Component {

    state =  {
        tabs: [
            {id: 0, name: "Explanatorium", color: Config.lightmode.colors_primary.red},
            {id: 1, name: "Power Search", color: Config.lightmode.colors_primary.green},
            {id: 2, name: "My Sessions", color: Config.lightmode.colors_primary.pink},
            {id: 3, name: "Account", color: Config.lightmode.colors_primary.blue},
            {id: 4, name: "Settings", color: Config.lightmode.colors_primary.orange}
        ],
        currentTab: 0,
        keysDown: []
    }

    handleTabChange = (i) => {
        this.setState({currentTab: i});
        console.log(this.state);
    }

    handleKeystrokes = (e) => {

        if (!this.state.keysDown.includes(e.code)) {
            this.setState({keysDown: [...this.state.keysDown, e.code]});
            console.log(this.state.keysDown);
        }
        
        if (this.state.keysDown.includes('ShiftLeft') || this.state.keysDown.includes('ShiftRight')) {
            var newTabInd = this.state.currentTab;
            if(e.code === 'ArrowUp'){
                e.preventDefault();
                
                newTabInd = this.state.currentTab - 1;
                if (newTabInd < 0) {
                    newTabInd += 1;
                }
                this.setState({currentTab: newTabInd});
            }
            else if(e.code === 'ArrowDown'){
                e.preventDefault();
    
                newTabInd = this.state.currentTab + 1;
                if (newTabInd >= this.state.tabs.length) {
                    newTabInd -= 1;
                }
                this.setState({currentTab: newTabInd});
            }
        }
    }

    cleanupKeystrokes = (e) => {
        if (this.state.keysDown.includes(e.code)) {
            this.setState({keysDown: this.state.keysDown.filter(k => k !== e.code)})
        }
        console.log(this.state.keysDown);
    }

    render() {
        return (
            <div className='mainAppContainer' tabIndex="0" onKeyDown={this.handleKeystrokes} onKeyUp={this.cleanupKeystrokes}>
                <TabbedView tabs={this.state.tabs} currentTab={this.state.currentTab} onTabChange={this.handleTabChange}/>
                <MainWindow tabs={this.state.tabs} currentTab={this.state.currentTab}/>
                
            </div>
        )
    }
}


// Slider that slides to a tab any time a slider is clicked
class TabSlider extends React.Component {

        render() {
            
            return (
                <div className="tabSlider"></div>
            )
        }
}




export default App;
