import React from 'react';
import './mainWindow.css';

import ExplanatoriumWindow from './explanatoriumWindow/explanatoriumWindow'
import PowersearchWindow from './powersearchWindow/powersearchWindow'
import SessionsWindow from './sessionsWindow/sessionsWindow'
import AccountWindow from './accountWindow/accountWindow'
import SettingsWindow from './settingsWindow/settingsWindow'



class MainWindow extends React.Component {

    displayWindow() {
        switch(this.props.currentTab) {
            case 0:
                return (
                    <ExplanatoriumWindow color={this.props.tabs[this.props.currentTab].color}/>
                )
            case 1:
                return (
                    <PowersearchWindow color={this.props.tabs[this.props.currentTab].color}/>
                )
            case 2:
                return (
                    <SessionsWindow color={this.props.tabs[this.props.currentTab].color}/>
                )
            case 3:
                return (
                    <AccountWindow color={this.props.tabs[this.props.currentTab].color}/>
                )
            case 4:
                return (
                    <SettingsWindow color={this.props.tabs[this.props.currentTab].color}/>
                )
            default:
                return (<div></div>)
        }
    }

    render() {
        return (
            <div className='mainWindow'>
                {this.displayWindow()}
            </div>
        )
    }
}

export default MainWindow;