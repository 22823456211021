// Import React, associated CSS
import React from 'react';
import './tab.css'

// Import all SVG icons
import SettingsIcon from '../../../../static/icons/settings_icon.svg'
import SessionIcon from '../../../../static/icons/session_icon.svg' 
import AccountIcon from '../../../../static/icons/account_icon.svg'
import PowerSearchIcon from '../../../../static/icons/powersearch_icon.svg' 
import ExplanatoriumIcon from '../../../../static/icons/explanatorium_icon.svg'



// Class representing each Tab on the side TabbedView
class Tab extends React.Component {
    
    // State stores name of the tab and associated SVG element ID
    state = {
        name: "",
        svgElement: ""
    }

    // Styles stores background color based on passed in prop data
    styles = {
        backgroundColor: this.props.color
    }

    // Constructor assigns state variables based on props
    constructor(props) {
        super(props)
        var elem = ""
        
        switch (this.props.name) {
            case "Explanatorium":
                elem = "eAHLE6Jz7us1"
                break
            case "Power Search":
                elem = "eqzAUpYhg0t1"
                break
            case "My Sessions":
                elem = "eDQUPtNA3Mq1"
                break
            case "Account":
                elem = "e6FdswT9W2Y1"
                break
            case "Settings":
                elem = "eyYet8h0lTm1"
                break
            default:
                break
        }


        this.state = {name: this.props.name, svgElement: elem}
    }

    // Function to get the correct HTML <object> reperesenting the animated SVG data based on props
    getTabIcon() {
        switch (this.props.name) {
            case "Explanatorium":
                return <object id={"tab-" + this.props.id + "-icon"} className="tab-icon no-select" type="image/svg+xml" data={ExplanatoriumIcon}> </object>
            case "Power Search":
                return <object id={"tab-" + this.props.id + "-icon"} className="tab-icon no-select" type="image/svg+xml" data={PowerSearchIcon}> </object>
            case "My Sessions":
                return <object id={"tab-" + this.props.id + "-icon"} className="tab-icon no-select" type="image/svg+xml" data={SessionIcon}> </object>
            case "Account":
                return <object id={"tab-" + this.props.id + "-icon"} className="tab-icon no-select" type="image/svg+xml" data={AccountIcon}> </object>
            case "Settings":
                return <object id={"tab-" + this.props.id + "-icon"} className="tab-icon no-select" type="image/svg+xml" data={SettingsIcon}> </object>
            default:
                return <div></div>
        }
    }

    // Function to start the SVG animation
    startAnimation = () => {
        let object1 = document.getElementById("tab-" + this.props.id + "-icon");
        let svg = object1.contentDocument.getElementById(this.state.svgElement);

        svg.svgatorPlayer.ready(function(player) {
            console.log("playing!");
            player.play();
        });
        
    }

    // Function to reverse the SVG animation
    reverseAnimation = () => {
        let object1 = document.getElementById("tab-" + this.props.id + "-icon");
        let svg = object1.contentDocument.getElementById(this.state.svgElement);

        svg.svgatorPlayer.ready(function(player) {
            console.log("reversing!");
            player.reverse()
        });
    }

    // Function to handle a tab switch event, making a call to the parent element's handler
    switchToTab = () => {
        
        this.props.onTabChange(this.props.id);
    }

    
    // Render element
    render() {

        var active = this.props.id === this.props.currentTab ? " active" : ""

        return (
            
            <div className={"tab no-select" + active} style={this.styles} onMouseEnter={this.startAnimation} onMouseLeave={this.reverseAnimation} onClick={this.switchToTab}>
                {this.getTabIcon()}
                <div className={"tab-name no-select"}>
                    {this.props.name}
                </div>
            </div>
        )
    }
}
export default Tab;