import React from 'react';
import './resizableLR.css'

import FeatureCard from '../featureCard/featureCard';

class ResizableLR extends React.Component {

    constructor(props) {
        super(props);
        this.resizerRef = React.createRef();
        this.leftRef = React.createRef();
        this.rightRef = React.createRef();
        this.resizeLRRef = React.createRef();

        this.x = 0;
        this.y = 0;
        this.leftWidth = 0;

    }

    resizeDownHandler = (e) => {
        // Get the current mouse position
        this.x = e.clientX;
        this.y = e.clientY;
        this.leftWidth = this.leftRef.current.getBoundingClientRect().width;
        
        this.resizeLRRef.current.addEventListener('mousemove', this.resizeMovedHandler);
        this.resizeLRRef.current.addEventListener('mouseup', this.resizeReleasedHandler);
    }

    resizeMovedHandler = (e) => {
        const dx = e.clientX - this.x;
        const dy = e.clientY - this.y;

        
        const newLeftWidth = ((this.leftWidth + dx) * 100) / this.resizerRef.current.parentNode.getBoundingClientRect().width;
        
        if (25 < newLeftWidth && newLeftWidth < 95) {
            this.leftRef.current.style.width = `${newLeftWidth}%`;
            this.rightRef.current.style.display = 'flex';
        }
        else if (95 < newLeftWidth) {
            this.leftRef.current.style.width = `${100}%`;
            this.rightRef.current.style.display = 'none';
        }


        this.resizerRef.current.style.cursor = 'col-resize';
        document.body.style.cursor = 'col-resize';

        this.leftRef.current.style.userSelect = 'none';
        this.leftRef.current.style.pointerEvents = 'none';

        this.rightRef.current.userSelect = 'none';
        this.rightRef.current.style.pointerEvents = 'none';
        
    }

    resizeReleasedHandler = (e) => {


        this.resizerRef.current.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        this.leftRef.current.style.removeProperty('user-select');
        this.leftRef.current.style.removeProperty('pointer-events');

        this.rightRef.current.style.removeProperty('user-select');
        this.rightRef.current.style.removeProperty('pointer-events');


        this.resizeLRRef.current.removeEventListener('mousemove', this.resizeMovedHandler);
        this.resizeLRRef.current.removeEventListener('mouseup', this.resizeReleasedHandler);
    }

    render() {

        return (
            <div className='resizableLR' ref={this.resizeLRRef}>
                <div className='resizableLeft' ref={this.leftRef}></div>
                <div className='resizer' ref={this.resizerRef} onMouseDown={this.resizeDownHandler} onClick={this.resizeClickHandler}></div>
                <div className='resizableRight' ref={this.rightRef}>
                    {this.props.rightComponent}
                </div>
            </div>
        )
        
    }

}

export default ResizableLR;