import React from 'react';

import NavBar from './navBar/navBar.js'

import MainGraphic from '../../static/icons/explanatory_main_graphic.svg' 



import './home.css'
import '../config.css'


class Home extends React.Component {

    render() {
        return (
            <div className='homepageContainer'>
                <NavBar></NavBar>
                <div className="titleCard">
                    <div className='titleCardContent'>
                        <div className="titleCardLeft">
                            <h1 className="titleCardMessage">Data Analytics <br /><span className="titleCardMessageAccent">in One Click.</span></h1>
                            <h2 className='titleCardSubmessage'>Create AI-powered dashboards and insights in seconds. Explanatory offers one-click solutions to help you get the most out of your data, using powerful no-code machine learning models.</h2>
                        </div>
                        <div className="titleCardRight">
                            <object className="titleCardMainGraphic" type="image/svg+xml" data={MainGraphic}> </object>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
