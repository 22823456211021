// Import React, associated CSS
import React from 'react';
import './tab.css'

// Import all SVG icons
import LogoIcon from '../../../../static/icons/logo_icon.svg' 


class LogoTab extends React.Component {

    state = {
        svgElement: "eCZ8WDYSQN61"
    }

    styles = {
        backgroundColor: '#ffffff'
    }

    
    goHome() {
        alert("Home!")
    }

    // Function to start the SVG animation
    startAnimation = () => {
        let object1 = document.getElementById("tab-logo-icon");
        let svg = object1.contentDocument.getElementById(this.state.svgElement);

        svg.svgatorPlayer.ready(function(player) {
            console.log("playing!");
            player.play();
        });
        
    }

    // Render element
    render() {
        return (
            <div className="logotab" style={this.styles} onLoad={this.startAnimation} onClick={this.goHome}>
                <object id='tab-logo-icon' className="logotab-icon no-select" type="image/svg+xml" data={LogoIcon}> </object>
            </div>
        )
    }
}

export default LogoTab;