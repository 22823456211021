import React from 'react';
import './featureCard.css'
import '../../../../config.css'

class FeatureCard extends React.Component {


    render() {
        return (
            <div className='featureCard'>
                <div className='featureCardHeader'>
                    <div className='featureCardLogo'>

                    </div>
                    <div className='featureCardHeaderText'>Rewrite</div>
                </div>
                <div className='featureCardDescription'>Change the phrasing of text in a variety of different styles and tones.</div>
                <div className='featureCardContent'></div>
            </div>
        )
        
    }

}

export default FeatureCard;