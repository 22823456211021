import React from 'react';


import './navBar.css'

import LogoIcon from '../../../static/icons/logo_icon.svg' 


class NavBar extends React.Component {

    // TODO: Make this component responsive!

    render() {
        return (
            <div className='navbar'>
                <div className='navbarContent'>
                    <div className='navbarTabs'>
                        <div className='navbarTab'>
                            <div className="navbarLogoWrapper">
                                <object className="navbarLogo" type="image/svg+xml" data={LogoIcon}> </object>
                                <div className="navbarLogoText">Explanatory<span className='navbarLogoTextAI'>.ai</span></div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className='signupButtons'>
                        <div className='getNotifications'>Get Notifications</div>
                        <div className='getInvolved'>Get Involved</div>
                    </div>
                </div>
            </div>
        )
        
    }
}

export default NavBar;