import React from 'react';
import './tabbedView.css';

import Tab from './tab/tab';
import LogoTab from './tab/logoTab';


// Holds all the tabs / buttons clickable to change the app's current view
class TabbedView extends React.Component {


    render() {
        return (
                <div className="tabView disable-scrollbars">
                    <LogoTab/>
                    {
                        this.props.tabs.map(
                            t => <Tab 
                                    key={t.id} 
                                    id={t.id} 
                                    name={t.name} 
                                    icon={t.icon} 
                                    color={t.color} 
                                    onTabChange={this.props.onTabChange}
                                    currentTab={this.props.currentTab}
                                    />
                        )
                    }
                    <div className='spacer'></div>
                </div>
        )
    }

}

export default TabbedView;