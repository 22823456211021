import React from 'react';
import '../../../config.css'
import ResizableLR from './resizableLR/resizableLR';
import FeatureCard from './featureCard/featureCard';

class ExplanatoriumWindow extends React.Component {

    state =  {
        loaded: false
    }

    styles = {
        color: this.props.color
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        // call api or anything
        setInterval(() => {
            this.setState({loaded: true});
        }, 10);
        
    }

    render() {
        console.log("Component has been rendered");
        let animate = this.state.loaded ? " fade-up-complete" : " fade-up-start";

        return (
            <div className='contentWindow'>
                <div className='windowHeaderBox'>
                    <h1 className={'windowHeader' + animate} style={this.styles} ref={this.myRef}>Explanatorium</h1>
                    <h2 className={'windowSubheader' + animate}>Analyze data like a boss.</h2>
                </div>
                
                <ResizableLR rightComponent={<FeatureCard/>}/>
            </div>
        )
    }
}

export default ExplanatoriumWindow;